<template>
  <v-row v-if="Lb_IsLoaded">
    <v-col
      cols="12"
    >
      <base-card>
        <v-card-title>{{$t('general.final_marks')}}</v-card-title>
        <v-card-text>
          <v-row
            align="center"
            class="ml-2"
          >
            <v-col
              class="d-flex"
              cols="11"
              sm="5"
            >
              <v-select
                item-value="ID"
                item-text="Name"
                :items="La_Diplomas"
                :label="$t('general.select_your_diploma')"
                @change="getDiplomaSubjects"
                dense
              />
            </v-col>
          </v-row>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    {{$t('general.subject')}}
                  </th>
                  <th class="text-left">
                    {{$t('general.mark')}}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in La_DiplomaSubjects"
                  :key="item.id"
                >
                  <td>{{ item.name }}</td>
                  <td>{{ item.mark }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </base-card>
    </v-col>
  </v-row>
</template>
<script>
import store from '@/store'
import { get } from '../../../worker/worker-api';
  export default {
    metaInfo () {
      return{
        // title will be injected into parent titleTemplate
        title: this.$t('general.final_marks'),
      }
    },
    props: {
      // AdressRolleID from Child
      ChildAdressRolle: {
        type: Number,
        default: 0
      }
    },    
    data () {
      return {
        La_Diplomas: [],
        La_Courses:[],
        La_DiplomaSubjects:[],
        Lb_IsLoaded:false
      }
    },
    methods: {
        getFinalMarks(Ai_SSP_AddressRoleID,Ai_PlanningPeriodID) {
          store.dispatch('changeThemeLoadingState', true);
          this.Lb_IsLoaded =false;
          return get(`${process.env.VUE_APP_SMT_API_URL}/student/${Ai_SSP_AddressRoleID}/final-marks/planning-period/${Ai_PlanningPeriodID}`, {})
          .then(response => {
            this.La_Diplomas = response.diplomas;
            this.La_Courses = response.subjects;
            setTimeout(() => store.dispatch('changeThemeLoadingState', false), 700)
            this.Lb_IsLoaded = true;
        })
        .catch(error => {
          this.Lb_IsLoaded = true;
          store.dispatch('changeThemeLoadingState', false);
        });
      },
      getDiplomaSubjects(Ai_DiplomaID) {
        const La_DiplomaSubjects = this.La_Courses.filter(subjectsInArray => subjectsInArray.calculationsId === Ai_DiplomaID); 
        this.La_DiplomaSubjects = La_DiplomaSubjects;
      },
      
    },
    mounted() {
      if (this.ChildAdressRolle != 0){
      this.getFinalMarks(store.getters.getPlanningPeriodID,this.ChildAdressRolle);
      }// if
      else{
      this.getFinalMarks(store.getters.getPlanningPeriodID,this.$smt.getAddressRoleID(this.$route.meta.role));
      }
    }
  }
</script>
